<template>
  <div class="mainform mainform-dilog">
    <div class="mainHeader mag-btm">
      <span class="mag-btm-span" :title= dataBox.inquiryDesc>
        {{ dataBox.inquiryDesc }}
      </span>
      <i class="feiqi" v-if="dataBox.status === 5">已废弃</i>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form fbxj-table">
      <div class="xunjia-news">
        <span></span>询价信息
      </div>
      <div class="elrowflx">
        <div class="">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">询价编号</div>
              <div class="xjcont"> {{dataBox.inquiryCode}} </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">询价描述</div>
              <div class="xjcont"> {{dataBox.inquiryDesc}} </div>
            </el-col>
            <el-col class="clearfix" :span="12" v-if="dataBox.inquirySchema=='' || dataBox.inquirySchema!==null">
              <div class="xjtit">询价模式</div>
              <div class="xjcont">
                {{dataBox.inquirySchema=="1"?"公开身份公开报价":dataBox.inquirySchema=="2"?"公开身份隐藏报价":dataBox.inquirySchema=="3"?"隐藏身份公开报价":dataBox.inquirySchema=="4"?"隐藏身份隐藏报价":""}}
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">经营范围</div>
              <div class="xjcont">
                <span>
                  {{dataBox.businessScope=="SG"?"曙光":dataBox.businessScope=="KK"?"可控":dataBox.businessScope=="TL"?"腾龙":dataBox.businessScope=="NC"?"宁畅":dataBox.businessScope=="SC"?"数创":""}}
                </span>
                <span> - {{dataBox.companyCode}} </span>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">询价类型</div>
              <div class="xjcont">
                {{dataBox.inquiryType=="1"?"单一供应商询价":dataBox.inquiryType=="2"?"多家供应商询价":dataBox.inquiryType=="3"?"普通竞价":dataBox.inquiryType=="4"?"反向竞价":""}}
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">发布人</div>
              <div class="xjcont"> {{ dataBox.createUserName }} </div>
            </el-col>
            <el-col class="clearfix" :span="12" v-if="dataBox.biddingWay=='' || dataBox.biddingWay!==null">
              <div class="xjtit">竞价方式</div>
              <div class="xjcont">
                {{dataBox.biddingWay=="1"?"单轮竞价":dataBox.biddingWay=="2"?"多轮竞价":""}}
                <span v-if="dataBox.biddingWay==2"> ({{dataBox.biddingBz}}轮) </span>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">报价截止日期</div>
              <div class="xjcont"> {{dataBox.inquiryEndTime}} </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">报价指标</div>
              <div class="xjcont xjcont-span">
                <span :key="index" v-for="(item, index) in dataBox.targetList">
                  {{ item }}
                </span>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">状态</div>
              <div class="xjcont">
                {{dataBox.status=="0"?"草稿":dataBox.status=="1"?"待报价":dataBox.status=="2"?"待审批":dataBox.status=="3"?"待处理":dataBox.status=="4"?"已结束":dataBox.status=="5"?"废弃":dataBox.status=="6"?"截止报价":""}}
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit" style="padding-top:2px">询价附件</div>
              <div class="xjcont xjcont-span is-disabled"> 
                <el-upload :on-preview="handlePreviewFile" :file-list="dataBox.fileInfoList"></el-upload>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">显示报价</div>
              <el-checkbox disabled v-model="dataBox.isShowInquiry"></el-checkbox>
            </el-col>
          </el-row>
        </div>
        <div class="xunjia-news" style="margin-top:-20px">
            <span></span>明细项
        </div>
        <div class="elrowflx">
            <div class="">
                <el-table stripe :data="inquiryMaterialMap.data" style="width: 100%">
                  <el-table-column v-for="(item) in inquiryMaterialMap.title" :key='item' :prop='item' :label='item'></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="xunjia-news">
            <span></span>受邀供应商
        </div>
        <div class="elrowflx">
            <div class="">
                <el-table class="table" :data="bidSupplierList" style="width: 100%">
                  <el-table-column label="序号" width="60px">
                    <template #default="scope">
                      {{scope.$index+1}}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="erpCode" label="供应商编码" width="180px"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="name" label="供应商名称" width=""></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactName" label="联系人" width="150px"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactPhone" label="手机号" width="150px"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactMail" label="邮箱" width=""></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="bz" label="备注" width=""></el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 报价 -->
        <div class="baojia">
          <div class="xunjia-news">
            <span></span>报价
            <i @click="exportlist()">报价明细导出</i>
          </div>
          <div class="">
            <quotation-Information class="quota" :formData="formData"></quotation-Information>
          </div>
        </div>
        <div class="action action-btn" v-if="dataBox.status !== 4 && dataBox.status !== 5 && dataBox.status !== 6">
          <div v-if="dataBox.status == 3 && dataBox.inquiryType == 3">
            <el-button class="preservation" type="primary" size="medium" @click="modify">修改</el-button>
            <el-button class="release" type="primary" size="medium" @click="Abandoned">废弃</el-button>
            <el-button v-if="dataBox.biddingWay !== 1" class="release" type="primary" size="medium" @click="aginInquiry">再次询价</el-button>
            <el-button class="release" type="primary" size="medium" @click="subApproval">提交审批</el-button>
          </div>
          <div v-if="dataBox.status == 3 && dataBox.inquiryType !== 3">
            <el-button class="release" type="primary" size="medium" @click="Abandoned">废弃</el-button>
            <el-button class="release" type="primary" size="medium" @click="subApproval">提交审批</el-button>
          </div>
          <div v-if="dataBox.status == 2">
            <el-button class="preservation" type="primary" size="medium" @click="modify">修改</el-button>
            <el-button class="release" type="primary" size="medium" @click="Abandoned">废弃</el-button>
            <el-button class="release" type="primary" size="medium" @click="reject">驳回</el-button>
            <el-button class="release" type="primary" size="medium" @click="adopt">通过</el-button>
          </div>
          <div v-if="dataBox.status == 1">
            <el-button class="preservation" type="primary" size="medium" @click="modify">修改</el-button>
            <el-button class="release" type="primary" size="medium" @click="Abandoned">废弃</el-button>
            <el-button class="release" type="primary" size="medium" @click="endOfferBox">结束报价</el-button>
          </div>
          <div v-if="dataBox.status == 0">
            <el-button class="preservation" type="primary" size="medium" @click="xqEdit">编辑</el-button>
          </div>
        </div>

        <!-- 操作日志 -->
        <!-- <div class="xunjia-rizhi">
          <el-collapse accordion>
            <el-collapse-item title="操作日志">
              <div class="czrz">待报价</div>
              <div class="czrz">2020-03-05 11:12:11 维莱特 发布询价项目</div>
            </el-collapse-item>
          </el-collapse>
        </div> -->
      </div>
      <approval-mind></approval-mind>
    </div>
    <!-- 修改弹框 -->
    <div class="modify xbj-modify">
      <el-dialog title="请选择报价截止时间" v-model="dialogModify" width="30%">
        <div class="role-cont">
          <el-form :model="endTimeData" ref="endTimeData" :rules="endTimeRules" label-width="110px" class="demo-ruleForm">
            <el-form-item class="item1" label="报价截止时间" prop="endTime">
              <el-date-picker type="datetime" @change="timeForm" placeholder="选择日期" v-model="endTimeData.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item class="item2">
              <el-button size="mini" @click="dialogModify = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="confirmModify('endTimeData')">确 定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <dialog-box ref="queryDialog" :dialogShow="dialogShow" :listId="listId" @handleClose='handleClose' title="再次询价" componentName="SecondInquiry" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>

    <dialog-box ref="queryDialog" :dialogShow="dialogOrderShow" :listId="listId" @handleClose='handleOrderClose' title="提交审批" componentName="SubmitApproval" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'orderAction', type:'primary', size:'mini'}]" @orderAction="orderBtn" ></dialog-box>
  </div>
</template>
<script>
import { baseURL, request, exportForm } from '@/assets/js/http.js'

import Component from '@/assets/js/components.js'
import { FlowCtlData, getApprPsn } from '@/assets/js/FlowManage.js'
import dayjs from 'dayjs'
import { MIME } from '@/assets/js/mime.js'
export default {
  components: Component.components,
  name: 'IssueDetails',
  props: [],
  data: function () {
    FlowCtlData.docid = ''
    return {
      dialogShow: false,
      dialogOrderShow: false,
      dialogModify: false,
      dataBox: {},
      listId: '',
      endTimeData: {
        endTime: ''
      },
      endTimeRules: {
        endTime: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      },
      formData: undefined,
      inquiryMaterialMap: {}, // 明细项数据
      bidSupplierList: [] // 受邀供应商数据
    }
  },
  created () {
    this.listId = this.$route.query.id
    FlowCtlData.flowid = 'issueinquiry'
    FlowCtlData.docid = this.listId
    FlowCtlData.initialize()
    this.getIdData()
  },
  mounted () {},
  methods: {
     handlePreviewFile (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id + '?type=1')
    },
    // 导出
    exportlist () {
      var file = { name: '报价明细.xls' }
      exportForm(`/api/inquiry/exportMaterial/${this.listId}`, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 根据ID请求数据
    getIdData () {
      this.ID = this.$route.query.id
      const id = this.listId
      if (id) {
        request('/api/inquiry/getById/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.dataBox = res.data
            this.dataBox.isShowInquiry = this.dataBox.isShowInquiry == 1 ? true : false
            this.inquiryMaterialMap = res.data.inquiryMaterialMap
            this.bidSupplierList = res.data.inquirySupplierList
          }
        })
        request('/api/inquiry/getMessageByPurchasing/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.formData = res.data
          }
        })
      }
    },
    // 修改
    modify () {
      this.dialogModify = true
    },
    // 确定修改
    confirmModify (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {
            inquiryEndTime: this.endTimeData.endTime,
            id: this.$route.query.id
          }
          request('/api/inquiry/discard', 'post', obj).then((res) => {
            if (res.code === '200') {
              this.$message({
                showClose: true,
                type: 'success',
                message: '修改成功'
              })
              this.dialogModify = false
              this.getIdData()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 废弃
    release () {
      const obj = {
        id: this.$route.query.id,
        status: 5
      }
      request('/api/inquiry/discard', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '已废弃'
          })
          this.$router.push({
            path: '/Invitation/InquiryAndQuotation'
          })
        }
      })
    },
    // 废弃弹框
    Abandoned () {
      this.$confirm('确认要废弃吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.release()
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 结束报价
    endOffer () {
      const obj = {
        id: this.$route.query.id,
        status: 3
      }
      request('/api/inquiry/discard', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '已结束报价'
          })
          this.getIdData()
        }
      })
    },
    // 结束报价弹框
    endOfferBox () {
      this.$confirm('确认要结束报价吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.endOffer()
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 再次询价
    aginInquiry () {
      this.dialogShow = true
    },
    // 提交审批
    subApproval () {
      this.dialogOrderShow = true
    },
    // 编辑
    xqEdit () {
      this.$router.push({
        path: '/Invitation/IssueInquiry',
        query: {
          id: this.$route.query.id
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 选择报价截止日期转换格式
    timeForm (val) {
      if (this.endTimeData.endTime) {
        this.endTimeData.endTime = dayjs(this.endTimeData.endTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.endTimeData.endTime = ''
      }
    },
    // 点击再次询价弹框里的确定按钮
    async action () {
      var onelist = this.$refs.queryDialog.$refs.content.$refs.secondList.model.checkBoxList
      var twolist = this.$refs.queryDialog.$refs.content.$refs.secondList.model.checkSupplier
      var RepetBidList = []
      for (var d of twolist) {
        for (var s of onelist) {
          if (s === d.label) {
            var temp = {}
            temp.code = d.value
            temp.quoteDesc = d.bz
            RepetBidList.push(temp)
          }
        }
      }
      const id = this.$route.query.id
      const obj = [
        ...RepetBidList
      ]
      if (RepetBidList.length > 0) {
        if (id) {
          /* const SupplierIds = []
          for (const item of RepetBidList) {
            SupplierIds.push(item.code)
          } */
          /* const apprData = {
            contact: '',
            tjsp: 'f'
          } */
          request('/api/inquiry/againInquiry?inquiryId=' + id, 'post', obj).then((response) => {
            if (response.code === '200') {
              this.$message({
                showClose: true,
                message: '询价成功',
                type: 'success'
              })
              this.dialogShow = false
              this.$router.push({
                path: '/Invitation/InquiryAndQuotation'
              })
            }
          })
          /* await request('/api/system/user/getUserListBySupplierId', 'post', SupplierIds).then((response) => {
            if (response.code === '200') {
              for (const item of response.data) {
                if (this.dataBox.businessScope === item.businessScope) {
                  apprData.contact = apprData.contact + item.username + ';'
                }
              }
            }
          }) */
          /*           FlowCtlData.getNodeAction('TJ', apprData).then(
            (val) => {
              if (val) {
                FlowCtlData.alertDialog(this).then(() => {
                  FlowCtlData.setFormData(FlowCtlData, obj)
                  request('/api/inquiry/againInquiry?inquiryId=' + id, 'post', obj).then((response) => {
                    if (response.code === '200') {
                      FlowCtlData.setNodeAction().then(
                        () => {
                          this.$message({
                            showClose: true,
                            message: '询价成功',
                            type: 'success'
                          })
                          this.dialogShow = false
                          this.$router.push({
                            path: '/Invitation/InquiryAndQuotation'
                          })
                        }
                      ).catch(() => {
                        this.$message({
                          showClose: true,
                          message: '发布失败',
                          type: 'error'
                        })
                      })
                    }
                  })
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消提交'
                  })
                })
              }
            }
          ) */
        }
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择需要报价的供应商'
        })
      }
    },

    // 点击提交审批弹框里的确定按钮
    async orderBtn () {
      var materialData = this.$refs.queryDialog.$refs.content.$refs.queryList.model.wuLiao
      var isSyncSap = this.$refs.queryDialog.$refs.content.$refs.queryList.model.sap
      var bz = this.$refs.queryDialog.$refs.content.$refs.queryList.model.bz

      var oneglist = this.$refs.queryDialog.$refs.content.$refs.queryList.model.checkBoxList
      var twoglist = this.$refs.queryDialog.$refs.content.$refs.queryList.model.checkSupplier
      var RepetSubList = []
      for (var d of twoglist) {
        for (var s of oneglist) {
          if (s === d.label) {
            var temps = {}
            temps.code = d.value
            temps.bz = bz
            temps.material = materialData.toString().toString()
            temps.isSyncSap = isSyncSap
            RepetSubList.push(temps)
          }
        }
      }

      if (RepetSubList.length <= 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择需要审批的供应商'
        })
        return false
      }

      var kst = true
      RepetSubList.forEach(k => {
        if (k.material === ',' || k.material === '') {
          kst = false
        } else {
          for (const item in materialData) {
            if (RepetSubList.length > 1 && k.material) {
              try {
                console.log(materialData[item])
                RepetSubList[item].material = materialData[item].toString().toString()
              } catch (e) {}
            }
          }
        }
      })
      if (!kst) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择物料'
        })
        return false
      }

      const id = this.$route.query.id
      const obj = [
        ...RepetSubList
      ]

      if (id) {
        const apprData = {
          contact: '',
          tjsp: 'y'
        }
        await getApprPsn('551625315191681024').then((psns) => {
          apprData.leader = psns
        })
        obj.businessScope = this.dataBox.businessScope
        FlowCtlData.getNodeAction('TJ', apprData).then(
          (val) => {
            if (val) {
              FlowCtlData.alertDialog(this).then(() => {
                obj.approvalComplete = this.dataBox.approvalComplete
                FlowCtlData.setFormData(FlowCtlData, obj)
                request('/api/inquiry/approval?inquiryId=' + id + '&approval=' + FlowCtlData.nextflowactionmsg.psnMsg.psnId, 'post', obj).then((response) => {
                  if (response.code === '200') {
                    FlowCtlData.setNodeAction().then(
                      () => {
                        this.$message({
                          showClose: true,
                          message: '提交成功',
                          type: 'success'
                        })
                        this.dialogOrderShow = false
                        this.$router.push({
                          path: '/Invitation/InquiryAndQuotation'
                        })
                      }
                    ).catch(() => {
                      this.$message({
                        showClose: true,
                        message: '提交失败',
                        type: 'error'
                      })
                    })
                  }
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消提交'
                })
              })
            }
          }
        )
      }
    },
    // 审批驳回
    reject () {
      const obj = {}
      obj.id = this.$route.query.id
      obj.businessScope = this.dataBox.businessScope
      FlowCtlData.getNodeAction('BH', obj).then((val) => {
        if (val) {
          FlowCtlData.alertBHDialog(this).then(({ value }) => {
            obj.approvalComplete = this.dataBox.approvalComplete
            FlowCtlData.setFormData(FlowCtlData, obj)
            obj.status = 4
            request('/api/inquiry/update', 'post', obj).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction(value).then((val) => {
                  if (val) {
                    FlowCtlData.formAlert(this, 'success')
                    this.$router.push('/Invitation/InquiryAndQuotation')
                  } else {
                    FlowCtlData.formAlert(this, 'error')
                  }
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    // 审批通过
    adopt () {
      const obj = {}
      obj.id = this.$route.query.id
      obj.businessScope = this.dataBox.businessScope
      FlowCtlData.getNodeAction('TJ', obj).then(
        (val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              obj.approvalComplete = this.dataBox.approvalComplete
              FlowCtlData.setFormData(FlowCtlData, obj)
              obj.status = 4
              request('/api/inquiry/update', 'post', obj).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction().then(
                    () => {
                      this.$message({
                        showClose: true,
                        message: '提交成功',
                        type: 'success'
                      })
                      this.dialogOrderShow = false
                      this.$router.push({
                        path: '/Invitation/InquiryAndQuotation'
                      })
                    }
                  ).catch(() => {
                    this.$message({
                      showClose: true,
                      message: '提交失败',
                      type: 'error'
                    })
                  })
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      )
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    handleOrderClose () {
      this.dialogOrderShow = false
    }
  },
  watch: {
    $route: {
      handler () {
        this.listId = this.$route.query.id
        this.getIdData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
:deep(.is-disabled){
    .el-upload-list__item:hover .el-icon-close{
        display: none!important;
    }
    .el-icon-close-tip{
        display: none!important;
    }
}
:deep(.el-upload) {
    display: none;
}
:deep(.el-upload-list__item:first-child) {
    margin-top: 0;
}
  .mag-btm{
    .mag-btm-span{
      display: inline-block;
      max-width: calc(100% - 140px);
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow: hidden;
      height: 60px;
      color: #00aaff;
    }
  }
  .mainform-dilog{
    .dialogBox .el-overlay .el-dialog{
      width: 555px !important;
    }
    .dialogBox .el-overlay .el-dialog .el-dialog__body{
      padding: 20px 40px;
    }
  }
  .fbxj-table{
    .xunjia-news{
      color: #666;
      font-size: 16px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      position: relative;
      span{
        display: inline-block;
        width: 3px;
        background: #ccc;
        vertical-align: -4px;
        height: 20px;
        background: #4487fa;
        margin-right: 5px;
      }
      i{
        cursor: pointer;
        position:  absolute;
        right: 10px;
        color: #4487fa;
        font-weight: 500;
      }
    }
  }
  .feiqi{
    margin-left: 10px;
    font-size: 14px;
    border: 1px solid #F56C6C;
    padding: 0 5px 2px;
    color: #F56C6C;
    vertical-align: top;
  }
  .elrowflx :deep(.el-col){
    margin-bottom: 20px
  }
  .elrowflx{
    .elwidth{
      margin: 0 auto !important;
      max-width: 1200px;
    }
    .xjtit{
      float: left;
      margin-right: 20px;
      min-width: 120px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
    }
    .mxtable{
      .el-table td{
        padding: 9px 0;
      }
      float: left;
      width: calc(100% - 140px);
    }
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
    }
    :deep(.el-table__header-wrapper .el-table__header th .cell){
      line-height: 34px;
    }
    :deep(.el-table .el-table__empty-block){
      min-height: 42px;
    }
    :deep(.el-table .el-table__empty-block .el-table__empty-text){
      line-height: 42px;
      font-size: 14px;
    }
    .action-btn{
      margin: 10px 0 50px;
    }
    .xunjia-rizhi{
      margin-bottom: 60px;
      .el-collapse-item__header{
        font-size: 16px;
        color: #666;
        border-bottom: 1px solid #e4e4e4;
        &:before {
          content: " ";
          display: inline-block;
          width: 3px;
          height: 20px;
          background: #4487fa;
          vertical-align: text-top;
          margin-right: 5px;
      }
      }
      .el-collapse{
        border-top: none;
      }
      .el-collapse-item__content{
        color: #666;
        font-size: 14px;
        padding: 10px 30px;
        .czrz:before {
          content: " ";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #4487fa;
          vertical-align: 2px;
          margin-right: 6px;
        }
      }
    }
  }
  .xbj-modify{
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-dialog){
      width: 500px !important;
      margin: 0 !important;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    :deep(.el-pagination){
      position: initial;
      text-align: center;
      padding: 20px 0;
    }
    .el-overlay{
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .item1{
      margin: 0;
      padding: 50px 60px;
    }
    .item2{
      border-top: 1px solid #e4e4e4;
      text-align: right;
      padding: 7px 30px 7px;
      margin-bottom: 0;
    }
  }
  .xjcont-span span{
    display: inline-block;
    margin-right: 5px;
  }
</style>
